@import '@/_variables.scss';

.pageWrapper {
  display: flex;
  flex-direction: column;
  background: #000000;
  color: #ffffff;
  overflow-y: scroll;
  height: 100%;
}
.contentsWrap {
  margin: 25px 24px 80px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

.message {
  white-space: pre-wrap;
  text-align: center;
  font-size: 19.2px;
  font-weight: bold;
  color: #fff;
  line-height: 1.5;
  margin: 62px auto;
  width: 100%;
  p {
    margin: 25px 0;
  }
}

.linkWrap {
  width: 100%;
  max-width: 288px;
  margin: 48px auto;
}
