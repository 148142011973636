@import '@/_variables.scss';

.listWrap {
  list-style: none;
  padding: 19px 16px;
  border-bottom: 1px solid #ececec;
  &:last-of-type {
    border: none;
  }
}
.labelTitle {
  font-size: 12px;
  font-weight: bold;
  line-height: 150%;
  position: relative;
  width: 100%;
  .toNext {
    position: absolute;
    width: 8px;
    height: 12px;
    top: 0;
    right: 0;
  }
}
.type-premium {
  color: #ff4d00;
}

.type-disabled {
  pointer-events: none;
  color: #cccccc;
}
