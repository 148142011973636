@import '@/_variables.scss';

.pageWrap {
    width: 100%;
    height: 100%;
    background: #ffffff;
    color: #000000;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
}
.linkWrap {
    text-align: center;
    padding: 0 0 16px;
    margin: 0 auto;
    margin-top: 20px;
    width: 81%;
    max-width: 288px;
    a {
        color: #FF4D00;
    }
}
.backButton {
    Button {
        background-color: white;
        color: #1F1F1F;
        border: solid 1px #E4E4E4;
    }
}
.header {
    width: 100%;
    padding: 24px 24px 12px;
    border-bottom: 1px solid #ececec;
    background-color: #ffffff;
    position: relative;
    position: sticky;
    top: 0;
    left: 0;
    background: #ffffff;
    z-index: 9;
    height:50px;//追加
    display: flex;//追加
    align-items: center;//追加
    .toBack {
        display: block;
        width: 9.15px;
        height: 18px;
        position: relative;
        top: -6px;
        left: 0;
        img {
            display: 100%;
            width: 100%;
            height: 100%;
        }
    }
    .title {
        font-weight: bold;
        font-size: 12px;
        line-height: 150%;
        text-align: center;
        margin: 0 auto;
        padding: 0 9.15px;
        margin-top: -10px;
    }
}

.amountlistWrap {
    padding: 0 10px;
    width: 100%;
    border-bottom: 1px solid #ececec;
}

// お支払方法の選択(タイトル)
.paymentTitle {
    font-size: 14px;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
}
// お支払方法の選択ボックス
.paymentSelect {
    display: block;
    border: solid 1px #E3E3E3;
    border-radius: 5px;
    margin: 10px auto 10px auto;
    width: 90%;
    position: relative;
}
.notPaymentSelect {
    p {
        color: #9A9999 !important;
    }
}

// お支払方法選択のタイトル
.paymentExplanationTitle {
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    padding-top: 3px;
    padding-bottom: 3px;
}
// お支払方法選択の説明
.paymentExplanationText {
    color: #9A9999;
    small {
        display: block;
        font-size: 12px;
        margin-top: 3px;
        margin-bottom: 3px;
    }
}
ul li {
    margin-top: 3px;
    margin-bottom: 3px;
}

// ラジオボタン領域
.paymentRadioButton {
    display: inline-block;
    width: 15%;
    text-align: center;
}
// 支払方法文字領域
.paymentExplanation {
    display: inline-block;
    width: 85%;
    padding: 10px 10px 10px 0px;
}

// ラジオボタンを消す
input[type="radio"] {
    display: none;
  }

// ラジオボタンのスタイル(未選択)
.radioButton {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    padding: 3px;
    background-clip: content-box;
    border-color: #C4C4C4;

    position: absolute;
    top: 42%;
    left: 5%;
}
// ラジオボタンのスタイル(選択)
.radioButtonSelected  {
    border-color: #FF4D00;
    background-color: #FF4D00;
}
.radioButtonSelectedAu {
    border-color: #9c00ff;
    background-color: #9c00ff;
}

// お申込み内容 (コンビニ決済用のお名前とメールアドレス入力欄)
.convenienceData {
    width: 90%;
    margin: auto;
}
// お申込み内容入力フォームのラベル
.convenienceDataLabel {
    margin: 15px 0px 10px;
    font-size: 14px;
    // 必須
    strong {
        font-weight: normal;
        background-color: #F84F4F;
        color: white;
        font-size: 12px;
        padding: 2px 5px;
        margin-right: 5px;
        border-radius: 5px;
    }
}
// お名前とメールアドレス入力フォーム
.convenienceDataInput {
    width: 100%;
    padding: 10px 12px;
    background: #FDFDFD;
    border: solid 1px #E1E1E1;
    box-sizing: border-box;
    border-radius: 5px;
}
// 入力フォームエラー(バリデーションエラー時に、入力欄を赤く表示する)
.error {
    border: solid 1px #F41818
}
// バリデーションエラー文言
.validationErrorText {
    color: #FF4D00;
}
// プレスホルダー
::placeholder {
    color: #DADADA;
}

// スクロールバーのスタイル
div[id="contentsAreaPayment"] {
    height: 90%;
    overflow-y: auto;
    scrollbar-color: #6969dd #e0e0e0;
    scrollbar-width: thin;
  }
  div[id="contentsAreaPayment"]::-webkit-scrollbar {
    display:block;
    width: 10px;
  }
  div[id="contentsAreaPayment"]::-webkit-scrollbar-track {
    background-color: #eee;
  }
  div[id="contentsAreaPayment"]::-webkit-scrollbar-thumb {
    background-color:#ccc;
    border-radius:5px;
}

// 注意事項文言のスタイル
.attentionSection {
    width: 90%;
    margin: 10px auto 10px auto;
    background-color: #EFF2F2;
    padding: 10px;
    p {
        font-size: 12px;
        padding: 5px;
    }
    a {
        color: #FF4D00;
    }
    ul, ol {
        font-size: 12px;
    }
    li {
        margin-left: 20px;
    }
}
// 「注意事項」の文字
.attentiontext {
    text-align: center;
    color: #485252;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
}
// 注意事項の丸
.attentionCircle:before {
    content: "";
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: #D9D9D9;
    border-radius: 50%;
    position:  relative;
    left: -5px;
    margin-left: -10px;
}