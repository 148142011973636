@import '@/variables.scss';

.telasaHeader {
  padding: 20px 48px 20px 20px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  img {
    display: block;
    height: 45px;
    width: 110px;
  }
  a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    &:hover {
      color: #000;
      opacity: 0.5;
    }
    @include mq() {
      font-size: 16.8px;
    }
  }
}
