@import '@/_variables.scss';

.amountlistWrap {
  padding: 0 10px;
  width: 100%;
  border-bottom: 1px solid #ececec;
}

.linkWrap {
  text-align: center;
  padding: 0 0 16px;
  margin: 0 auto;
  width: 81%;
  max-width: 288px;
}

.serviceText {
  padding: 12px 32px 4px;
  font-size: 13px;
  line-height: 150%;
  color: #c6961c;
  text-align: center;
}

.checkWrap {
  text-align: center;
  padding: 0 0 16px;
  margin: 0 auto;
  width: fit-content;
}

.checkBoxWrap {
  font-size: 14px;
  line-height: 1.5;
  padding: 8px;
  margin: 16px;
  text-align: left;
  input {
    height: 24px;
    width: 40px;
    margin-bottom: 2px;
  }
  a {
    font-weight: bold;
    color: #ff4d00;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    &:hover {
      opacity: 0.5;
    }
  }
}
