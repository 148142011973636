@import '@/_variables.scss';

.pageWrap {
  width: 100%;
  height: 100%;
  background: #ffffff;
  color: #000000;
  padding-top:54px;
  box-sizing: border-box;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.header {
  padding: 24px 24px 12px;
  border-bottom: 1px solid #ececec;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9;
  box-sizing:border-box;
  width: 100%;//追加
  height:54px;//追加
  display: flex;//追加
  align-items: center;//追加
  .toBack {
    display: block;
    width: 9.15px;
    height: 18px;
    position: relative;
    top: -6px;
    left: 0;
    img {
      display: 100%;
      width: 100%;
      height: 100%;
    }
  }
  .title {
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    margin: 0 auto;
    padding: 0 9.15px;
    margin-top: -10px;
  }
}

div[id="contentArea"]{
  height: 100%;
  overflow-y: auto;
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    display:block;
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #eee;
  }
  &::-webkit-scrollbar-thumb {
    background-color:#ccc;
    border-radius:5px;
  }
}