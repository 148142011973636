@import '@/_variables.scss';

/*デフォルト*/
.RadioWrap {
  padding: 4px 0;
  border-bottom: 1px solid #ececec;
  &:last-of-type {
    border: none;
  }
}
.input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.label {
  position: relative;
  display: block;
  margin: 0;
  padding: 16px 12px;
  border-radius: 6px;
  padding: 16px 12px 16px 36px;
  &-disabled {
    cursor: not-allowed;
  }
  &-checked {
    background-color: rgba(255, 77, 0, 0.1);
  }
  &-hover {
    background-color: rgba(255, 77, 0, 0.1);
  }
  &-active {
    background-color: rgba(255, 77, 0, 0.1);
  }
}
.radio {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  top:41%;
  left:12px;
  &-checked {
    border: 1px solid #ff4d00;
    &::after {
      content: '';
      background-color: #ff4d00;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
  &-checked-au {
    border: 1px solid #9c00ff;
    &::after {
      content: '';
      background-color: #9c00ff;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
}

.radioLabel {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  word-break: break-word;
  &-checked {
    color: #ff4d00;
  }
  &-checked-au {
    color: #9c00ff;
  }
}

/*ダークモード*/
.RadioWrapDark {
  padding: 4px 0;
  border: 1px solid #ececec;
  border-radius: 5px;
  margin-top: 12px;
}
.inputDark {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.labelDark {
  position: relative;
  display: block;
  margin: 0;
  padding: 16px 12px;
  border-radius: 6px;
  padding: 16px 12px 16px 36px;
  &-disabled {
    cursor: not-allowed;
  }
}
.radioDark {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 12px;
  &-checked {
    border: 1px solid #ff4d00;
    &::after {
      content: '';
      background-color: #ff4d00;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
}
.radioLabelDark {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  word-break: break-word;
}
