@import '@/variables.scss';

.footer {
  padding: 30px 50px 20px;
  background: #f0f0f0;
  width: 100%;
  margin: auto auto 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  align-items: center;

  img {
    display: block;
    width: 85px;
    height: 30px;
    margin-bottom: 10px;
  }
  .ulWrap {
    ul {
      text-align: center;
      padding: 2.5px 0;
      display: block;
      li {
        display: inline-block;
        a {
          display: inline-block;
          padding: 5px 0;
          margin: 5px;
          text-decoration: none;
          -webkit-transition: all 0.5s ease-out;
          transition: all 0.5s ease-out;
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
  @include mq() {
    font-size: 14.4px;
  }
  .copyright {
    display: block;
    margin: 10px 0 10px;
    text-align: center;
    line-height: 1.5;
    p {
      margin-bottom: 1rem;
    }
  }

}

.license {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  div {
    margin-right: 8px;
  }
  img {
    height: 48px;
    width: auto;
  }
  p {
    font-size: 10px;
  }
}