#contentAnnounce .buttonText::before {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url(../../assets/icon/icn-signin.svg);
  background-size: cover;
  content: '';
  margin: -3px 4px;
}
