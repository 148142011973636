@import '@/variables.scss';

.playerWrapper {
  height: 100%;
  width: 100%;
  div {
    height: 100%;
  }
  iframe {
    height: 100%;
    width: 100%;
  }
}
