@import "@/_variables.scss";
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300;400;500;700;800&display=swap');

.pageWrap {
  width: 100%;
  height: 100%;
  background: #ffffff;
  color: #000000;
  padding-top: 54px;
  box-sizing: border-box;
  font-family: 'M PLUS 1p', sans-serif;
  &::-webkit-scrollbar {
    display: none;
  }
}

.header {
  border-bottom: 1px solid #ececec;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9;
  box-sizing: border-box;
  width: 100%; //追加
  height: 54px; //追加
  display: flex; //追加
  align-items: center; //追加
  .toBack {
    display: block;
    width: 9.15px;
    height: 18px;
    position: relative;
    top: -6px;
    left: 0;
    img {
      display: 100%;
      width: 100%;
      height: 100%;
    }
  }
  .title {
    font-size: 20px;
    line-height: 150%;
    text-align: left;
    padding: 16px 0 16px 16px;
  }
}

.contentArea {
  overflow: hidden;
}

.contentTop {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #000000;
  padding: 20px 30px;
}

.contentMiddle {
  padding: 0 20px 20px;
  button {
    border-radius: 36px;
    background-color: #eb5505;
    height: 43px;
  }
}

.contentBottom {
  background-color: #f5f5f5;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 170%;
  padding: 20px 30px 60px;
  ul {
    list-style: none;
    li:before {
      content: "•";
      margin-right: 6px;
  }
  }
}
