@import '@/variables.scss';

@mixin pageWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
}
.pageWrapperUp {
  @include pageWrapper;
  background: #000000;
  color: #ffffff;
}
.pageWrapperTelasa {
  @include pageWrapper;
}
.pageWrapperAu {
  @include pageWrapper;
}

@mixin contentsWrap {
  padding: 80px 24px;
  align-items: center;
  justify-content: center;
  .iconWrap {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #ff4d00;
    position: relative;
    margin: 0 auto;
    .icn {
      display: block;
      width: 53.33px;
      height: 39.64px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
  .message1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    margin-top: 30px;
  }
  .message2 {
    margin-top: 16px;
    display: block;
    font-size: 14px;
    line-height: 200%;
    text-align: center;
    white-space: pre-wrap;
  }
  .linkWrap {
    width: 100%;
    max-width: 288px;
    margin: 32px auto 0;
  }
}

.contentsWrapUp {
  flex: 1;
  display: flex;
  background: #000000;
  color: #ffffff;
  @include contentsWrap;
}

.contentsWrapTelasa {
  @include contentsWrap;
  // 事前にご確認ください
  .purchaseCompletedAttention {
    background-color: #EFF2F2 !important;
    border-radius: 3px;
    width: 100% !important;
    margin: 10px auto;
    padding: 15px 10px;
    max-width: 320px;
    p{
      line-height: 1;
    }
    .icn{
      margin-right: 6px;
    }
  }
  .purchaseCompletedAttention p {
    color: #FF5353;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
  .purchaseCompletedAttention div {
    font-size: 15px;
    margin: 15px 0px;
  }
}

.contentsWrapAu {
  flex: 1;
  display: flex;
  @include contentsWrap;
  // 事前にご確認ください
  .purchaseCompletedAttention {
    background-color: #EFF2F2 !important;
    border-radius: 3px;
    width: 100% !important;
    margin: 10px auto;
    padding: 15px 10px;
    max-width: 320px;
    p{
      line-height: 1;
    }
    .icn{
      margin-right: 6px;
    }
  }
  .purchaseCompletedAttention p {
    color: #FF5353;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
  .purchaseCompletedAttention div {
    font-size: 15px;
    margin: 15px 0px;
  }
  .iconWrap {
    background: #9c00ff !important;
  }
}
