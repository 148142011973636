@import '@/_variables.scss';

.pageWrap {
  width: 100%;
  height: 100%;
  background: #ffffff;
  color: #000000;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.header {
  width: 100%;
  padding: 24px 24px 12px;
  border-bottom: 1px solid #ececec;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 9;
  height:50px;//追加
  display: flex;//追加
  align-items: center;//追加
  .title {
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    margin: 0 auto;
  }
}
.listWrap {
  padding: 0 16px;
  width: 100%;
  border-bottom: 1px solid #ececec;
}
.type-total {
  span {
    font-weight: bold;
  }
}
.labelTitle {
  font-size: 12px;
  line-height: 150%;
}
.type-premium {
  color: #ff4d00;
}

.linkWrap {
  text-align: center;
  padding: 24px 0 32px;
  margin: 0 auto;
  width: 81%;
  max-width: 288px;
  > p {
    font-size: 16px;
    line-height: 150%;
    margin: 0 0 24px;
    white-space: pre-wrap;
    height: calc(100vh - 194px);
    overflow-y: scroll;
    text-align: left;
  }
  button {
    height: 60px;
    width: 100%;
    display: block;
    position: relative;
    border: none;
    border-radius: 8px;
    color: $COLOR_WHITE;
    text-decoration: none;
    font-weight: bold;
    background: $BASE_COLOR_PRIMARY_100;
  }
}
.alphaUcolor {
  button {
    background: #9c00ff !important;
  }
}

.noItemPageWrap {
  width: 100%;
  height: 100%;
  background: #ffffff;
  color: #000000;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noItemText {
  font-size: 20px;
  font-weight: bold;
}

.purchaseButton {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  color: $COLOR_WHITE;
  background: $BASE_COLOR_PRIMARY_100;
  text-decoration: none;
  font-weight: bold;
  &:after {
    content: '';
    width: 7.32px;
    height: 12px;
    display: block;
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    background-image: url(../../assets/icon/icn-next.svg);
    background-size: 100% 100%;
  }
}

p[id="allow_view_area"] {
  margin: 0 -5% 24px;
  overflow-y: auto;
  overflow-x: hidden;
  a{
    text-decoration:underline;
  }
  *{
    max-width:100%;
  }
}
