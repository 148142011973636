@import '@/_variables.scss';

// スマホ以外 & スマホ共通
.items {
  padding: 16px 24px;
  font-size: 12px;
  box-sizing: border-box;
  width: 100%;
  font-weight: bold;

  .frame {
    border: solid 1px gray;
    border-radius: 5px;

    input {
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      outline: none;
      font-size: 14px;
    }
  }
}

// 適用ボタン
.button {
  Button {
    width: 100%;
    height: 80%;
    font-size: 0.5em;
  }
}

// スマホ用ボタン
.applyButtonSp {
  Button {
    padding: 5px 5px;
  }
}

// メッセージ
.message {
  font-size: 12px;
  margin-bottom: 4px;
  color: #ff4d00;
  text-align: center;
}

// スマホ以外(PC・タブレット)
.other {
  div {
    display: inline-block;
  }

  // クーポンコード
  div:nth-child(1) {
    width: 32%;
  }

  .frame {
    width: 68%;

    // クーポンコード入力
    div:nth-child(1) {
      width: 70%;
      margin: 5px;

      input {
        margin-left: -5px;
      }
    }

    // 適用ボタン
    div:nth-child(2) {
      width: 20%;
      margin: auto;
    }
  }
}

// スマホレイアウト
.sp {
  div {
    display: inline-block;
  }

  // クーポンコード
  div:nth-child(1) {
    width: 38%;
  }

  .frame {
    width: 60%;
    margin-top: 10px;

    // クーポンコード入力
    div:nth-child(1) {
      width: 70%;
    }

    // 適用ボタン
    div:nth-child(2) {
      width: 25%;
      margin-left: 5px;
    }
  }

  // メッセージ
  .message {
    width: 100%;
    font-weight: normal;
    font-size: 11px;
    margin-top: 10px;
    margin-bottom: -5px;
  }
}

.freecoupon {
  div {
    display: block;
  }

  // クーポンコード
  .caption {
    width: auto !important;
    font-size: 1rem;
    text-align: center;
    margin: 30px 0px;
  }

  .frame {
    text-align: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 4px 0px;

    // クーポンコード入力
    div:nth-child(1) {
      text-align: center;
      width: 100%;
    }
  }

}