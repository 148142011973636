@import '@/_variables.scss';

.processWrap {
  &.dark {
    border-bottom: 1px solid #484848;
  }
  &.light {
    border-bottom: 1px solid #ececec;
  }
  &.none {
    border-bottom: none;
  }

  padding: 16px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  .image {
    width: 80px;
    @include mq() {
      width: 160px;
    }
    object-fit: contain;
    aspect-ratio: 16/9;
    &.dark {
      background: #222;
    }
    &.light {
      background: #f8f8f8;
    }
  }
  .textWrap {
    padding: 0 0 0 16px;
    width: 100%;
    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 8px;
    }
    .ticketTitle {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 8px;
      &.dark {
        color: #cccccc;
      }
      &.light {
        color: #7f7f7f;
      }
    }
    .desc {
      font-size: 12px;
      line-height: 1.5;
      margin-bottom: 8px;
    }
    .archive {
      display: inline-block;
      padding: 0 5px;
      font-size: 10px;
      line-height: 2;
      border-radius: 3px;
      margin-left: 16px;
      &.dark {
        background: #222;
      }
      &.light {
        background: #ececec;
      }
    }
    .toLive {
      display: block;
      width: 100%;
      height: 32px;
      line-height: 32px;
      border-radius: 6px;
      margin-top: 8px;
    }
    .status-now {
      background-color: #ff4d00;
      text-align: center;
      font-size: 0;
      &:before {
        content: '';
        width: 12px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        background-image: url(../../../assets/icon/icn-play.svg);
        background-size: 100% 100%;
      }
      &:after {
        content: '視聴';
        color: #ffffff;
        display: inline-block;
        vertical-align: middle;
        line-height: 32px;
        font-size: 14px;
        margin-left: 13px;
        font-weight: bold;
      }
    }
    .status-before {
      background-color: #484848;
      &:before {
        content: '配信前';
        color: #ffffff;
        display: block;
        font-size: 14px;
        line-height: 32px;
        text-align: center;
        font-weight: bold;
      }
    }
    .status-after {
      background-color: #484848;
      &:before {
        content: '配信終了';
        color: #ffffff;
        display: block;
        font-size: 14px;
        line-height: 32px;
        text-align: center;
        font-weight: bold;
      }
    }
  }
  // マイページの未払いチケットの左側に空白が出来ていたため、空白を消す
  .myPageUnpaid {
    margin-left: -30px;
  }
  @include mq() {
    .textWrap {
      padding: 0 96px 0 16px;
      width: 100%;
      position: relative;
      .toLive {
        display: block;
        width: 88px;
        height: 48px;
        line-height: 48px;
        border-radius: 6px;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
      }
      .status-now {
        background-color: #ff4d00;
        text-align: center;
        font-size: 0;
        &:before {
          content: '';
          width: 14px;
          height: 18px;
          display: inline-block;
          vertical-align: middle;
          background-image: url(../../../assets/icon/icn-play.svg);
          background-size: 100% 100%;
        }
        &:after {
          content: '視聴';
          color: #ffffff;
          display: inline-block;
          vertical-align: middle;
          font-size: 16px;
          margin-left: 13px;
          font-weight: bold;
          line-height: 48px;
        }
      }
      .status-before {
        &.dark {
          background-color: #484848;
          &:before {
            color: #fff;
          }
        }
        &.light {
          background-color: #ececec;
          &:before {
            color: #000;
          }
        }
        &:before {
          content: '配信前';
          color: #ffffff;
          display: block;
          font-size: 16px;
          line-height: 48px;
          text-align: center;
          font-weight: bold;
        }
      }
      .status-after {
        &.dark {
          background-color: #484848;
          &:before {
            color: #fff;
          }
        }
        &.light {
          background-color: #ececec;
          &:before {
            color: #000;
          }
        }
        &:before {
          content: '配信終了';
          display: block;
          font-size: 16px;
          line-height: 48px;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
}

.listWrap {
  padding: 0 16px 15px;
  width: 100%;
}

// お支払待ちの詳細ボタン
.unpaidDetail {
  display: block;
  border-radius: 6px;
  background-color: white;
  text-align: center;
  width: 88px;
  height: 48px;
  border: solid 1px black;
  border-radius: 6px;
  &:before {
    content: '詳細';
    color: black;
    display: block;
    font-size: 16px;
    line-height: 48px;
    text-align: center;
    font-weight: bold;
  }
}

// 送付策情報表示モーダルのタイトルを固定
.personalDataModal {
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
}
// 送付先情報の確認
.personalData {
  display: block;
  width: 88px;
  height: 48px;
  border-radius: 6px;
  margin-top: 8px;
  background-color: white;
  position: absolute;
  top: 50px;
  right: 0;
  border: solid 1px black;
  border-radius: 6px;
  &:after {
    content: '送付先情報の確認';
    color: black;
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    font-weight: bold;
    padding: 10px;
  }
  // スマートフォン向けデザイン
  @media screen and (max-width: 767.5px) {
    width: 100%;
    height: 32px;
    text-align: center;
    position: relative;
    top: 0px;
  }
}
// 送付先情報ベース
.personalDataBase {
  padding: 0 32px;
  color: black;
}
// 送付先情報入力のタイトル
.contentsTitle {
  text-align: center;
  p {
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
    padding: 0.5rem;
    border-bottom: 2px solid #ff4d00;
    margin: 1rem auto 0.5rem;
  }
}
.alphaUcolor {
  p {
    border-bottom: 2px solid #9c00ff !important;
  }
}

// 送付先情報入力の説明文
.contentsSubject {
  font-size: 12px;
  text-align: left;
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .contentsAttention {
    color: #ff4d00;
  }
}
.personalDataSection {
  padding-top: 10px;
  padding-bottom: 10px;
}
// 送付先情報ラベル
.personalDataLabel {
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 10px;
}
// 送付先情報
.personalDataText {
  font-size: 1.2em !important;
  // 半角英数字の場合、枠からはみ出すため
  word-wrap: break-word;
}
.pageWrap {
  display: flex;
  height: 45vh;
  justify-content: center;
  align-items: center;
  margin: 0;
}

div[id="contentAreaPersonalInfo"] {
  height: 90%;
  overflow-y: auto;
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
  padding-bottom: 20px;
}
div[id="contentAreaPersonalInfo"]::-webkit-scrollbar {
  display:block;
  width: 10px;
}
div[id="contentAreaPersonalInfo"]::-webkit-scrollbar-track {
  background-color: #eee;
}
div[id="contentAreaPersonalInfo"]::-webkit-scrollbar-thumb {
  background-color:#ccc;
  border-radius:5px;
}

.backgtound-au {
  background-color: #9c00ff !important;
}
