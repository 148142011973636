@import '@/_variables.scss';

@mixin pageWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}
.pageWrapperUp {
  @include pageWrapper;
  background: #000000;
  color: #ffffff;
}
.pageWrapperTelasa {
  @include pageWrapper;
}
.pageWrapperAu {
  @include pageWrapper;
}

.contentsWrap {
  padding: 0 20px;
  flex: 1;
  @include mq() {
    padding: 0 48px;
  }
  .bredList {
    padding: 2px 0 8px;
    margin: 0;
    font-size: 12px;
    li {
      list-style: none;
      display: inline-block;
      vertical-align: middle;
      &:not(:last-of-type) {
        &:after {
          content: '»';
          font-size: 12px;
          font-weight: bold;
          display: inline-block;
          line-height: 20px;
          color: #ffffff;
          padding: 0 6px;
        }
      }
      a {
        display: inline-block;
        line-height: 1.5;
        color: #ff4d00;
        -webkit-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
        &:hover {
          opacity: 0.5;
        }
      }
      span {
        display: inline-block;
        line-height: 1.5;
        color: #ffffff;
      }
    }
    @include mq() {
      padding: 5px 0 12px;
      font-size: 14.4px;
    }
  }

  @mixin pageTitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    @include mq() {
      font-size: 32px;
    }
  }
  .pageTitleUp {
    @include pageTitle;
    color: #ffffff;
    margin: 0;
  }
  .pageTitleTelasa {
    @include pageTitle;
    margin-top: 24px;
  }
  .pageTitleAu {
    @include pageTitle;
    margin-top: 24px;
  }

  @mixin idInfoWrap {
    color: #fff;
    padding: 18px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px auto 0;
    max-width: 736px;
    button {
      margin-left: 8px;
      height: 34px;
    }
    .container {
      .idBlock {
        display: flex;
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 24px;
        .key {
          white-space: nowrap;
        }
        .id {
          // margin-left: 8px;
          font-weight: bold;
          word-break: break-all;
        }
      }
    }
    .textWrap {
      font-size: 12px;
      .toAuMyPage {
        font-size: 14px;
        line-height: 18px;
        color: #ff4d00;
        text-decoration: none;
        -webkit-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
        &:hover {
          opacity: 0.5;
        }
      }
    }

    .logout {
      border: none;
      outline: none;
      padding: 8px;
      font-size: 12px;
      line-height: 1.5;
      display: inline-block;
      background: #ffffff;
      border-radius: 4px;
      color: #000000;
      font-weight: bold;
      flex: none;
    }

    .setting {
      border: none;
      outline: none;
      padding: 8px;
      font-size: 12px;
      line-height: 1.5;
      display: inline-block;
      background: #ffffff;
      border-radius: 4px;
      color: #000000;
      font-weight: bold;
      flex: none;
      text-align: center;
    }
  }

  .idInfoWrapUp {
    background: #222222;
    @include idInfoWrap;
  }
  .idInfoWrapTelasa {
    background: #555b5c;
    @include idInfoWrap;
  }
  .idInfoWrapAu {
    background: #222222;
    @include idInfoWrap;
  }

  .historyWrap {
    max-width: 736px;
    margin: 48px auto 151px;
    @include mq() {
      margin: 28px auto 141px;
    }
    h2 {
      font-size: 16px;
      font-weight: bold;
      line-height: 150%;
      margin: 0 0 38px;
    }
    p {
      margin-left: 16px;
      font-size: 14px;
    }
  }

  .unpaidSubject {
    margin-left: 0 !important;
    margin-top: -20px;
    margin-bottom: 20px;
  }
}
