@import '@/variables.scss';

.auHeader {
  padding: 20px 48px 20px 20px;
  color: #333;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  img {
    display: block;
    height: 45px;
    width: 110px;
  }
  a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    &:hover {
      color: #333;
      opacity: 0.5;
    }
    @include mq() {
      font-size: 16.8px;
    }
  }
}
