@import '@/variables.scss';

.pageWrap {
  height: 100%;
}

@mixin pageWrapper {
  height: 100%;
  overflow-y: scroll;
}
.pageWrapperUp {
  @include pageWrapper;
  background: #000000;
  color: #ffffff;
}
.pageWrapperTelasa {
  @include pageWrapper;
}
.pageWrapperAu {
  @include pageWrapper;
}
