@import '@/_variables.scss';

.pageWrap {
  width: 100%;
  height: 100%;
  background: #ffffff;
  color: #000000;
}
.header {
  width: 100%;
  padding: 24px 24px 12px;
  border-bottom: 1px solid #ececec;
  background-color: #ffffff;
  position: relative;
  position: sticky;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 9;
  height: 50px; //追加
  display: flex; //追加
  align-items: center; //追加
  .toBack {
    display: block;
    width: 9.15px;
    height: 18px;
    position: relative;
    top: -6px;
    left: 0;
    img {
      display: 100%;
      width: 100%;
      height: 100%;
    }
  }
  .title {
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    margin: 0 auto;
    padding: 0 9.15px;
    margin-top: -10px;
  }
}

.linkWrap {
  text-align: center;
  padding: 24px 0 32px;
  margin: 0 auto;
  width: 81%;
  max-width: 288px;
  p {
    font-size: 16px;
    line-height: 150%;
    margin: 0 0 24px;
  }
}
