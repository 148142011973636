@import '../../../variables.scss';

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 20px;
  background: #fff;

  @include mq('md') {
    padding: 0 48px 0 20px;
    height: 64px;
    min-height: 64px;
    margin-right: 0;
  }

  @include mq('xs') {
    padding: 18px 12px;
  }

  .logo {
    margin: auto auto auto 0;
    z-index: 5002;

    a {
      text-decoration: none;
      line-height: 0;
      display: block;

      img {
        height: 38px;

        @include mq('xs') {
          width: 164px;
          height: auto;
        }
      }
    }
  }

  .navi {
    margin: auto 0 auto auto;
    font-size: 0.875rem;

    @include mq('md') {
      width: auto;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      font-weight: 400;
      color: #ccc;

      li {
        display: block;
        margin: 0;

        &:not(:first-child) {
          margin-left: 24px;

          @include mq('md') {
            margin-left: 48px;
          }
        }

        a {
          text-decoration: none;
          color: #000;
          transition: all 0.5s ease-out;

          &:visited,
          &:focus {
            color: #000;
          }

          &:hover {
            font-weight: 400;
            color: #000;
            opacity: 0.5;
          }

          @include mq('md') {
            font-size: 16.8px;
          }
        }
      }
    }
  }
}
