@import '@/variables.scss';

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 9998;
}

.modalWrapper {
  width: 92%;
  max-width: 352px;
  min-width: 300px;
  max-height: 480px;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 9999;
  &::-webkit-scrollbar {
    display: none;
  }
  .modalHeader {
    padding: 24px 36px 12px 36px;
    border-bottom: 1px solid #ececec;
    .closeButton {
      display: block;
      position: absolute;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0;
      top: 25.5px;
      right: 21px;
      width: 15px;
      height: 15px;
      background-image: url(../../../assets/icon/icn-close.svg);
      background-size: 15px 15px;
      z-index: 999;
    }
    .backButton {
      display: block;
      position: absolute;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0;
      top: 25.5px;
      left: 21px;
      width: 9.15px;
      height: 15px;
      background-image: url(../../../assets/icon/icn-back.svg);
      background-size: 9.15px 15px;
      z-index: 999;
    }
    p {
      color: #000000;
      margin: 0;
      padding: 0;
      text-align: center;
      font-size: 12px;
      line-height: 1.5;
      font-weight: bold;
    }
  }
}

div[id="modalShowScroll"] {
  height: 100%;
}
