@import '../../../variables.scss';

.primary {
  background: $BASE_COLOR_PRIMARY_100;
}

.premium {
  background: $BASE_COLOR_PREMIUM_100;
}

.button {
  text-align: center;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  display: block;
  position: relative;
  color: $COLOR_WHITE;
  white-space: pre-wrap;
  &:disabled {
    background: #484848;
  }
  // 半角英数字をはみ出さないようにする(https://developer.mozilla.org/ja/docs/Web/CSS/word-break)
  word-break: break-word;
}

.toNext {
  &:after {
    content: '';
    width: 7.32px;
    height: 12px;
    display: block;
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    background-image: url(../../../assets/icon/icn-next.svg);
    background-size: 100% 100%;
  }
}

// 文言が長い場合、「>」アイコンの位置をずらす
.longToNext {
  &:after {
    content: '';
    width: 7.32px;
    height: 12px;
    display: block;
    position: absolute;
    top: 50%;
    right: 10px !important;
    transform: translateY(-50%);
    background-image: url(../../../assets/icon/icn-next.svg);
    background-size: 100% 100%;
  }
}

// 文言が長い場合のスタイル
.longText {
  width: 85%;
  margin: auto;
}

// alpha-Uのカラー
.alphaUcolor {
  background: #9c00ff !important;
}