/* colors */
$COLOR_WHITE: #ffffff;

$BASE_COLOR_PRIMARY_100: #ff4d00;

$BASE_COLOR_PREMIUM_100: #e07000;

/* media query */
$breakpoints: (
  'xs': 'screen and (max-width: 480px)',
  'sm': 'screen and (min-width: 480px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 1000px)',
  'xl': 'screen and (min-width: 1200px)',
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
