@import '@/variables.scss';

.upKKSWrapper {
  height: 100%;
}

.upKKSPlayer {
  height: 100%;
  width: 100%;
}
