@import '@/_variables.scss';

.pageWrap {
  width: 100%;
  height: 100%;
  background: #ffffff;
  color: #000000;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.linkWrap {
  text-align: center;
  padding: 0 0 16px;
  margin: 0 auto;
  width: 81%;
  max-width: 288px;
}
.backButton {
  Button {
    background-color: white;
    color: #1F1F1F;
    border: solid 1px #E4E4E4;
  }
}
.header {
    width: 100%;
    padding: 24px 24px 12px;
    border-bottom: 1px solid #ececec;
    background-color: #ffffff;
    position: relative;
    position: sticky;
    top: 0;
    left: 0;
    background: #ffffff;
    z-index: 9;
    height:50px;//追加
    display: flex;//追加
    align-items: center;//追加
    .toBack {
      display: block;
      width: 9.15px;
      height: 18px;
      position: relative;
      top: -6px;
      left: 0;
      img {
        display: 100%;
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-weight: bold;
      font-size: 12px;
      line-height: 150%;
      text-align: center;
      margin: 0 auto;
      padding: 0 9.15px;
      margin-top: -10px;
    }
}

// 送付先情報ベース
.personalDataBase {
  padding: 0 32px;
}
// 送付先情報入力のタイトル
.contentsTitle {
  text-align: center;
  p {
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
    padding: 0.5rem;
    border-bottom: 2px solid #ff4d00;
    margin: 1rem auto 0.5rem;
  }
}
.alphaUcolor {
  p {
    border-bottom: 2px solid #9c00ff !important;
  }
}

// 送付先情報入力の説明文
.contentsSubject {
  font-size: 12px;
  text-align: left;
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .contentsAttention {
    color: #FF4D00;
  }
}
.personalDataSection {
  padding-top: 10px;
  padding-bottom: 10px;
}
// 送付先情報入力ラベル
.personalDataLabel {
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 10px;
}
// 送付先情報入力
.personalDataInput {
  width: 295px;
  padding: 10px 12px;
  background: #FDFDFD;
  border: solid 1px #E1E1E1;
  box-sizing: border-box;
  border-radius: 5px;
}
// バリデーションエラー時に、入力欄を赤く表示する
.error {
  border: solid 1px #F41818
}
// プレスホルダー
::placeholder {
  color: #DADADA;
}
// バリデーションエラー文言
.validationErrorText {
  color: #FF4D00;
}
// 入力内容確認
.inputConfirm {
  font-size: 1.2em;
  // 半角英数字の場合、枠からはみ出すため
  word-wrap: break-word;
}

// スクロールバー
div[id="contentAreaPersonalData"] {
  height: 90%;
  overflow-y: auto;
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
}
div[id="contentAreaPersonalData"]::-webkit-scrollbar {
  display:block;
  width: 10px;
}
div[id="contentAreaPersonalData"]::-webkit-scrollbar-track {
  background-color: #eee;
}
div[id="contentAreaPersonalData"]::-webkit-scrollbar-thumb {
  background-color:#ccc;
  border-radius:5px;
}