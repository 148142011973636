@import '../../../variables.scss';

$link-height: 60px;
.transitionLink {
  display: block;
  position: relative;
  width: 100%;
  height: $link-height;
  border: none;
  border-radius: 6px;
  color: $COLOR_WHITE;
  text-decoration: none;
  font-weight: bold;
  span {
    display: block;
    position: absolute;
    width: calc(100% - 80px);
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}
.primary {
  background: $BASE_COLOR_PRIMARY_100;
}

.premium {
  background: $BASE_COLOR_PREMIUM_100;
}

.toNext {
  &:after {
    content: '';
    width: 7.32px;
    height: 12px;
    display: block;
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    background-image: url(../../../assets/icon/icn-next.svg);
    background-size: 100% 100%;
  }
}

.reproduction {
  &:before {
    content: '';
    width: 14px;
    height: 18px;
    display: block;
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    background-image: url(../../../assets/icon/icn-reproduction.svg);
    background-size: 100% 100%;
  }
}

.noIcon {
  &:before {
    display: none;
  }
}

.disabled {
  pointer-events: none;
  background: #cccccc;
}

// alpha-Uのカラー
.alphaUcolor {
  background: #9c00ff !important;
}
