@import '@/_variables.scss';

@mixin pageWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}
.pageWrapperUp {
  @include pageWrapper;
  background: #000000;
  color: #ffffff;
}
.pageWrapperTelasa {
  @include pageWrapper;
}
.pageWrapperAu {
  @include pageWrapper;
  h2 {
    border-bottom: 1px solid #9c00ff !important;
  }
  button {
    background: #9c00ff !important;
  }
}

.contentsWrap {
  padding: 0 20px;
  flex: 1;
  @include mq() {
    padding: 0 48px;
  }
  .bredList {
    padding: 2px 0 8px;
    margin: 0;
    font-size: 12px;
    li {
      list-style: none;
      display: inline-block;
      vertical-align: middle;
      &:not(:last-of-type) {
        &:after {
          content: '»';
          font-size: 12px;
          font-weight: bold;
          display: inline-block;
          line-height: 20px;
          color: #ffffff;
          padding: 0 6px;
        }
      }
      a {
        display: inline-block;
        line-height: 1.5;
        color: #ff4d00;
        -webkit-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
        &:hover {
          opacity: 0.5;
        }
      }
      span {
        display: inline-block;
        line-height: 1.5;
        color: #ffffff;
      }
    }
    @include mq() {
      padding: 5px 0 12px;
      font-size: 14.4px;
    }
  }
  @mixin pageTitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    @include mq() {
      font-size: 32px;
    }
  }
  .pageTitleUp {
    @include pageTitle;
    color: #ffffff;
    margin: 0;
  }
  .pageTitleTelasa {
    @include pageTitle;
    margin-top: 24px;
  }
  .pageTitleAu {
    @include pageTitle;
    margin-top: 24px;
  }

  @mixin idInfoWrap {
    color: #ffffff;
    padding: 18px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px auto 0;
    max-width: 736px;
    button {
      margin-left: 8px;
      width: 76px;
      height: 34px;
    }
    .container {
      .idBlock {
        display: flex;
        font-size: 16px;
        line-height: 24px;
        .key {
          white-space: nowrap;
        }
        .id {
          // margin-left: 8px;
          font-weight: bold;
          word-break: break-all;
        }
      }
    }
    .logout {
      border: none;
      outline: none;
      padding: 8px;
      font-size: 12px;
      line-height: 1.5;
      display: inline-block;
      background: #ffffff;
      border-radius: 4px;
      color: #000000;
      font-weight: bold;
      flex: none;
    }
  }
  .idInfoWrapUp {
    background: #222222;
    @include idInfoWrap;
  }
  .idInfoWrapTelasa {
    background: #555b5c;
    @include idInfoWrap;
  }
  .idInfoWrapAu {
    background: #222222;
    @include idInfoWrap;
  }

  .sectionWrap {
    max-width: 736px;
    margin: 48px auto 48px;
    @include mq() {
      margin: 28px auto 28px;
    }
    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 150%;
      padding: 0 0 8px;
      border-bottom: 1px solid #ff4d00;
      margin: 48px 0 24px;
    }
    p {
      margin-left: 16px;
      font-size: 14px;
    }

    .toAuMyPage {
      display: flex;
      flex-direction: row;
      color: #ff4d00;
      text-decoration: none;
      -webkit-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
      &:hover {
        opacity: 0.5;
      }

      &:after {
        content: '';
        width: 16px;
        height: 16px;
        display: block;
        margin-left: 4px;
        background-image: url(../../../assets/icon/icn-new-window.svg);
        background-size: 100% 100%;
      }
    }
  }

  .buttonWrap {
    display: flex;
    justify-content: center;
    button {
      text-align: center;
      border-radius: 4px;
      text-decoration: none;
      color: #fff;
      font-weight: 700;
      padding: 18px 20px;
      -webkit-box-shadow: 0px 0px 5px rgba(1, 35, 41, 0.1);
      box-shadow: 0px 0px 5px rgba(1, 35, 41, 0.1);
      background-color: #ff4d00;
    }
  }
}
