@import '@/_variables.scss';

.listWrap {
  padding: 0px 24px; //変更
  margin: 22px 0; //追加
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid #ececec;
  &:last-of-type {
    border: none;
  }
}

.not-border {
  border: none;
  padding: 20px 24px 0;
}

.type-total {
  span {
    font-weight: bold;
  }
}
.labelTitle {
  font-size: 12px;
  font-weight: bold;
  line-height: 150%;
  white-space: pre-wrap;
  // 半角英数字だとはみ出すため
  word-break: break-word;
  width: 80%;
}
.type-ticket {
  color: #ff4d00;
}
.amount {
  font-size: 16px;
  line-height: 150%;
  white-space: nowrap;
}
.type-linethrough {
  text-decoration: line-through;
}
.ponta {
  font-size: 10px;
  margin-right: 24px;
  margin-bottom: 4px;
  color: #ff4d00;
  text-align: right;
}
