@import '../../../variables.scss';

.contentsWrap {
  &.dark {
    background: #000000;
    color: #ffffff;
  }
  &.light {
    background: #fff;
    color: #000;
  }

  padding: 0 46px;
  .iconWrap {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #de331c;
    position: relative;
    margin: 0 auto;
    .icn {
      display: block;
      width: 53.33px;
      height: 39.64px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
  .code {
    font-size: 12px;
    text-align: center;
    margin-top: 4px;
  }
  .message1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    margin-top: 30px;
  }
  .message2 {
    margin-top: 16px;
    display: block;
    font-size: 14px;
    line-height: 200%;
    text-align: center;
    white-space: pre-wrap;
  }
  .linkWrap {
    width: 100%;
    max-width: 288px;
    margin: 32px auto 0;
  }
}
