@import '@/_variables.scss';

@mixin pageWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}
.pageWrapperUp {
  @include pageWrapper;
  background: #000000;
  color: #ffffff;
}
.pageWrapperTelasa {
  @include pageWrapper;
}
.pageWrapperAu {
  @include pageWrapper;
  h2 {
    background: #9c00ff !important;
  }
}

.contentsWrap {
  margin: 0 48px 48px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 32px;
  font-weight: bold;
  margin-top: 48px;
  margin-bottom: 48px;
  width: 100%;
}

.auid {
  margin: 0 auto;
  width: 70%;
  min-width: 300px;
  max-width: 600px;
  p {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .button {
    margin-bottom: 48px;
  }
}

.border {
  width: 100%;
  height: 2px;
  background: #cecece;
}

.description {
  margin: 24px auto;
  width: 90%;
  .title {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .text {
    font-size: 12px;
  }
}
