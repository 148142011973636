@import '@/variables.scss';

.pageWrapper {
  display: flex;
  flex-direction: column;
  background: #000000;
  color: #ffffff;
  overflow-y: scroll;
  height: 100%;
}
.contentsWrap {
  margin: 80px 24px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
