@import '../../../variables.scss';

.LoaderWrap {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.loader {
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0 -30px 0 #777, 21px -21px 0 #888, 30px 0 0 #999, 21px 21px 0 #aaa,
    0 30px 0 #bbb, -21px 21px 0 #ccc, -30px 0 0 #ddd, -21px -21px 0 #eee;

  animation: rotate 1s steps(8) 0s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
