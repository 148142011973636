@import '@/_variables.scss';

.linkWrap {
  text-align: center;
  padding: 128px 0 0;
  margin: 0 auto;
  width: 81%;
  max-width: 288px;
  p {
    font-size: 16px;
    line-height: 150%;
    margin: 0 0 16px;
  }
}
