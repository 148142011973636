@import '@/variables.scss';

.pageWrap {
  height: 100%;
  background: #000000;
  color: #ffffff;
  overflow-y: scroll;
}

.pageWrapNoScroll {
  height: 100%;
  background: #000000;
  color: #ffffff;
}
