@import '@/variables.scss';

.footer {
  padding: 70px 48px 20px;
  background: #f0f0f0;
  img {
    display: block;
    width: auto;
    height: 23.65px;
  }
  .ulWrap {
    ul {
      margin: 20px 0 0 0;
      padding: 0;
      display: block;
      li {
        display: block;
        margin: 24px 0 0 0;
        a {
          text-decoration: none;
          font-size: 14px;
          color: #333;
          -webkit-transition: all 0.5s ease-out;
          transition: all 0.5s ease-out;
          @include mq() {
            font-size: 16.8px;
          }
          &:visited {
            color: #333;
          }
          &:focus {
            color: #333;
          }
          &:hover {
            color: #333;
            opacity: 0.5;
          }
        }
      }
    }
  }
  @include mq() {
    padding: 70px 48px 20px;
    .ulWrap {
      ul {
        display: inline-block;
        padding: 0;
        vertical-align: top;
        &:first-of-type {
          margin-right: 236px;
        }
      }
    }
  }
  .copyright {
    font-size: 10px;
    line-height: 2;
    color: #333;
    margin: 60px 0 0 0;
    @include mq() {
      font-size: 12px;
    }
  }
}

.license {
  padding-top: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  div {
    margin-right: 8px;
  }
  img {
    height: 48px;
    width: auto;
  }
  p {
    font-size: 10px;
    color: #333;
  }
}
