@import '@/variables.scss';

@mixin pageWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}
.pageWrapperUp {
  @include pageWrapper;
  background: #000000;
  color: #ffffff;
}
.pageWrapperTelasa {
  @include pageWrapper;
}
.pageWrapperAu {
  @include pageWrapper;
}
.contentsWrap {
  padding: 80px 24px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
