@import '@/variables.scss';

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modarlWrapper {
  width: 92%;
  max-width: 480px;
  min-width: 300px;
  background-color: #fff;
  border-radius: 8px;
}

.closeButton {
  float: right;
  background-color: #fff;
  border: none;
  height: 24px;
  width: 24px;
  font-size: 24px;
  cursor: pointer;
  margin-right: 0;
  margin-top: 4px;
}

.contents {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  padding: 8px 16px 16px;
}

.headline {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-top: 8px;
  margin-bottom: 0;
}

.text {
  font-size: 12px;
  color: #000;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 8px;
  margin-bottom: 16px;
  a {
    &:hover {
      text-decoration: underline;
    }
    text-decoration: none;
    color: $BASE_COLOR_PRIMARY_100;
  }
}

.notice {
  font-size: 12px;
  color: #000;
  margin: 0;
}

.nameInput {
  margin-top: 16px;
  padding: 0px 8px;
  height: 44px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  border: 1px solid #777;
  border-radius: 8px;
  display: block;
  appearance: none;
  color: #000;
  &:focus {
    border: 1px solid #777;
    border-radius: 8px;
  }
}

.loginButton {
  &:disabled {
    background-color: #777777;
  }
  display: block;
  margin-top: 8px;
  border: none;
  color: #fff;
  background-color: $BASE_COLOR_PRIMARY_100;
  font-size: 16px;
  font-weight: bold;
  height: 44px;
  width: 100%;
  border-radius: 8px;
  text-align: center;
}

.alphaUcolor {
  background: #9c00ff !important;
}

.alertText {
  font-size: 12px;
  font-weight: bold;
  color: red;
  margin: 4px 8px;
}
